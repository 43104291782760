import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { customMedia } from '../../common/customMedia'
import { SpBr } from '../../common/SpBr'

import { Image } from '../../common/Image'

export interface Props {
  imagePath: string
  closed: boolean
}

export const OfficePositionCard = (props: Props) => {
  const { imagePath, closed } = props

  return (
    <Container>
      <ImageWrapper>
        <Image path={imagePath} />
        {closed && (
          <ImageMask>
            <div>募集は終了しました</div>
          </ImageMask>
        )}
      </ImageWrapper>
      <DescriptionWrapper>
        <Type>
          <div>正社員雇用</div>
        </Type>
        <Title>医療事務</Title>
        <SalaryHeader>月給</SalaryHeader>
        <Salary>170,000円〜204,700円</Salary>
        <Description>
          ※試用期間があります
          <br />
          ※登録販売者資格を取得希望の方は支援致します
        </Description>
        <Border />
        <Description>
          未経験者の方・男性も大歓迎です。現場に慣れながら丁寧に指導をしていきます。経験者の方は、経験を生かせるように能力を考慮して働く環境を整えます。
        </Description>
      </DescriptionWrapper>
    </Container>
  )
}

const Container = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
  `}
`

const ImageWrapper = styled.div`
  height: 130px;
  width: 100%;
  position: relative;

  ${customMedia.lessThan('medium')`
    img {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      height: 130px !important;
      object-fit: cover;
      overflow: hidden;
    }
  `}
  ${customMedia.greaterThan('medium')`
    height: 330px;
    width: 50%;

    img {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      object-fit: cover;
      overflow: hidden;
      height: 330px !important;
    }
  `}
`

const ImageMask = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.4rem;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 1.8rem;
  `}

  background-color: rgba(0, 85, 161, .5);
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.2em;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
  }
`

const DescriptionWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 20px;
  `}
  ${customMedia.greaterThan('medium')`
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 330px;
    padding: 17px 30px;
    width: 50%;
  `}

  background-color: #fff;
  color: #0055a4;
  font-size: 1.4rem;
`

const Type = styled.div`
  text-align: center;

  div {
    border: 2px #0055a4 solid;
    border-radius: 18px;
    display: inline-block;
    font-weight: bold;
    letter-spacing: 0.1em;
    line-height: 26px;
    height: 28px;
    width: 120px;
  }
`

const Title = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.12em;
  margin-top: 10px;
`

const SalaryHeader = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
  `}

  background-color: #0055A4;
  border-radius: 18px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 0.24em;
  margin-top: 15px;
  padding: 2px 0;
  text-align: center;
`

const Salary = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
  `}

  margin-top: 10px;
`

const Border = styled.div`
  background-color: #0055a4;
  height: 2px;
  margin-top: 14px;
`

const Description = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
  `}

  letter-spacing: 0.02em;
  line-height: 24px;
  margin-top: 14px;
`
