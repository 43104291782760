import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { customMedia } from '../../components/common/customMedia'
import { Reveal, Animation } from 'react-genie'
import Pulse from 'react-reveal/Pulse'

import { Layout } from '../../components/Layout'
import { SEO } from '../../components/common/SEO'
import { Image } from '../../components/common/Image'
import { Jumbotron } from '../../components/common/Jumbotron'
import { Mission } from '../../components/common/Mission'
import { PositionCard } from '../../components/Recruit/PositionCard'
import { OfficePositionCard } from '../../components/Recruit/OfficePositionCard'
import { Message as MessageComponent } from '../../components/common/Message'
import { ContactUs } from '../../components/common/ContactUs'
import { SpBr } from '../../components/common/SpBr'
import { PcBr } from '../../components/common/PcBr'
import { Breadcrumb } from '../../components/common/Breadcrumb'

const RecruitMain = require('../../images/main1_pc.jpg')
const RecruitImage1 = require('../../images/recruit_image1.jpg')

const IndexPage = () => {
  const [hover, setHover] = useState(false)
  const [width, setWidth] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      setWidth(0)
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    handleResize()
  }, [])

  const handleHover = () => setHover(!hover)

  return (
    <Layout>
      <SEO
        title="軽井沢・佐久・御代田の薬局で薬剤師求人転職探しなら | シンビ堂"
        description="【求人・転職】軽井沢エリアで5店舗展開しているシンビ堂薬局です。軽井沢・佐久・御代田・小諸エリアで薬剤師として、個人の力を生かしませんか？マニュアルでなくコミュニケーションを大切にする職場です。福利厚生も充実！お気軽にお問合せください。"
        keywords={['薬剤師', '転職', '軽井沢', '佐久', '御代田', '小諸']}
      />
      <h1>薬剤師　求人 転職 軽井沢 佐久 御代田 小諸 薬局 | シンビ堂</h1>

      <Container>
        <Jumbotron
          imageSrc={RecruitMain}
          enTitle="RECRUIT"
          jaTitle="採用情報"
          objectPosition={30}
        />
        <BreadcrumbWrapper>
          <Breadcrumb color="blue" current="採用情報" />
        </BreadcrumbWrapper>
        <Reveal delay={500} animation={Animation.FadeInUp}>
          <Message>
            個人力を生かせる職場です。
            <br />
            自分の意思を最大限に
            <SpBr />
            生かしてみせんか？
          </Message>
        </Reveal>
        <Reveal delay={500} animation={Animation.FadeInUp}>
          <SubMessage>
            調剤薬局の現場は常にマニュアル化され管理されています。
            <PcBr />
            私たちの使命は「暮らしの信頼を提供する」ことです。
            <PcBr />
            お客様とのコミュニケーションを大切に会話のある薬局で働きませんか？
          </SubMessage>
        </Reveal>

        <MissionWrapper>
          <Mission showButton={false} />
        </MissionWrapper>

        <Communication>
          <Reveal delay={1000} animation={Animation.FadeIn}>
            <TabletImageWrapper>
              <Image path="recruit_tablet@2x.png" />
            </TabletImageWrapper>
          </Reveal>

          <Reveal delay={1200} animation={Animation.FadeIn}>
            <CommunicationTitle>
              シンビ堂はお薬だけでは
              <SpBr />
              ありません
            </CommunicationTitle>
          </Reveal>
          <Reveal delay={1200} animation={Animation.FadeIn}>
            <CommunicationSubTitle>
              <div>コミュニケーションから生まれる暮らしの安心を提供</div>
            </CommunicationSubTitle>
          </Reveal>

          <CommunicationContainer>
            <Home>
              <Reveal delay={1600} animation={Animation.FadeInLeft}>
                <Pulse delay={1700}>
                  <HomeIconWrapper>
                    <Image path="recruit_home@2x.png" />
                  </HomeIconWrapper>
                </Pulse>
                <CommunicationText>
                  地域の情報を
                  <br />
                  提供
                </CommunicationText>
              </Reveal>
            </Home>

            <Virus>
              <Reveal delay={2100} animation={Animation.FadeInLeft}>
                <Pulse delay={2200}>
                  <VirusIconWrapper>
                    <Image path="recruit_virus@2x.png" />
                  </VirusIconWrapper>
                </Pulse>
                <CommunicationText>
                  流行りの
                  <br />
                  病気の案内
                </CommunicationText>
              </Reveal>
            </Virus>

            <Vaccine>
              <Reveal delay={2600} animation={Animation.FadeInLeft}>
                <Pulse delay={2700}>
                  <VaccineIconWrapper>
                    <Image path="recruit_vaccine@2x.png" />
                  </VaccineIconWrapper>
                </Pulse>
                <CommunicationText>
                  日常でできる
                  <br />
                  予防策
                </CommunicationText>
              </Reveal>
            </Vaccine>

            <Hospital>
              <Reveal delay={3100} animation={Animation.FadeInLeft}>
                <Pulse delay={3200}>
                  <HospitalIconWrapper>
                    <Image path="recruit_hospital@2x.png" />
                  </HospitalIconWrapper>
                </Pulse>
                <CommunicationText>
                  お近くの病院や
                  <br />
                  地域情報の案内
                </CommunicationText>
              </Reveal>
            </Hospital>
          </CommunicationContainer>
        </Communication>

        <PositionContainer>
          <Reveal delay={1500} animation={Animation.FadeIn}>
            <EnvironmentMessage>
              社内の雰囲気はとても良い環境です。
            </EnvironmentMessage>
          </Reveal>

          <Reveal delay={1700} animation={Animation.FadeIn}>
            <RecruitMessage>
              あなたに合った勤務形態を
              <SpBr />
              選びませんか？
            </RecruitMessage>
          </Reveal>
          <PositionContent>
            <Reveal delay={2000} animation={Animation.FadeInUp}>
              <PositionCardWrapper>
                <PositionCard
                  imagePath="recruit1.jpg"
                  type="正社員雇用"
                  color="blue"
                  title={`経験を生かした薬剤師`}
                  salaryType="年収"
                  salaryMin="500万円"
                  salaryMax="800万円"
                  requirement="薬剤師資格（免許）をお持ちの方"
                  description="転職を考えている方へ、今までの経験を発揮できる場を提供します。現場の雰囲気はとても良いので、働きやすい環境です。"
                />
              </PositionCardWrapper>
            </Reveal>

            <Reveal delay={500} animation={Animation.FadeInUp}>
              <PositionCardWrapper>
                <PositionCard
                  imagePath="recruit2.jpg"
                  type="正社員雇用"
                  color="blue"
                  title={`研修充実！\n調剤薬局勤務未経験者`}
                  salaryType="年収"
                  salaryMin="450万円"
                  requirement={`薬剤師資格（免許）をお持ちの方\n薬剤師資格取得予定の方`}
                  description="未経験でもご安心ください。丁寧に指導していきます。現場の環境に慣れるまで、現場スタッフ全員でサポートします。"
                  showBalloon={true}
                />
              </PositionCardWrapper>
            </Reveal>

            <Reveal delay={500} animation={Animation.FadeInUp}>
              <PositionCardWrapper>
                <PositionCard
                  imagePath="recruit3.jpg"
                  type="パートタイム"
                  color="pink"
                  title={`シフト制 \nメリハリある勤務形態`}
                  salaryType="時給"
                  salaryMin="2000円"
                  requirement="薬剤師資格（免許）をお持ちの方"
                  description="様々な事情で生活にリズムが必要な場合は、シフト制の勤務も可能です。お気軽にご相談ください。"
                />
              </PositionCardWrapper>
            </Reveal>

            <Reveal delay={500} animation={Animation.FadeInUp}>
              <PositionCardWrapper>
                <PositionCard
                  imagePath="recruit4.jpg"
                  type="パートタイム"
                  color="pink"
                  title={`子育てと両立出来る薬剤師`}
                  salaryType="時給"
                  salaryMin="2000円"
                  requirement="薬剤師資格（免許）をお持ちの方"
                  description="子育てをしながら仕事をするのは大変です。せっかくの資格を生かせるように子育てと両立できるようにしていきます。"
                />
              </PositionCardWrapper>
            </Reveal>

            <Reveal delay={500} animation={Animation.FadeInUp}>
              <PositionCardWrapper>
                <OfficePositionCard imagePath="recruit5.jpg" closed={false} />
              </PositionCardWrapper>
            </Reveal>
          </PositionContent>
        </PositionContainer>

        <RobotContainer>
          <Reveal delay={500} animation={Animation.FadeIn}>
            <RobotTitle>
              業界唯一のフルオート
              <SpBr />
              調剤ロボットを導入
            </RobotTitle>
          </Reveal>
          <Reveal delay={700} animation={Animation.FadeIn}>
            <SpRobotImageWrapper>
              <Image path="robot_sp@2x.png" />
            </SpRobotImageWrapper>
            <PcRobotImageWrapper>
              <Image path="robot_pc@2x.png" />
            </PcRobotImageWrapper>
          </Reveal>
          <RobotDescription>
            <Reveal delay={900} animation={Animation.FadeInUp}>
              処方データを流すだけ。あとはロボットが調剤します。
              <PcBr />
              ロボットに任せるから薬品の取り間違い、秤量間違いがありません。
              <PcBr />
              見ていなくても、ダブルチェックの秤量で正確さを担保。
            </Reveal>
          </RobotDescription>
        </RobotContainer>
        <RecruitImage1Wrapper>
          <img src={RecruitImage1} alt={RecruitImage1} />
        </RecruitImage1Wrapper>

        <Allowance1Container>
          <Reveal delay={500} animation={Animation.FadeIn}>
            <Allowance1Title>
              住宅手当や福利厚生、
              <SpBr />
              産休育休が充実！
            </Allowance1Title>
          </Reveal>
          <Reveal delay={700} animation={Animation.FadeIn}>
            <Allowance1Description>
              暮らしの安心はお客様だけではありません。
              <PcBr />
              共に働くスタッフにも暮らしの安心を提供します。
              <PcBr />
              ワークバランスが見直されている昨今、プライベートの充実は何より大切なことです。
              <PcBr />
              安心して生活してもらえる環境を会社が提供することが、素敵な働きに変わると考えています。
            </Allowance1Description>
          </Reveal>
          <AllowanceTipContainer>
            <Reveal delay={800} animation={Animation.FadeIn}>
              <Pulse delay={850}>
                <AllowanceTip>
                  各種社会保険
                  <PcBr />
                  （健康保険 厚生年金 雇用保険）完備
                </AllowanceTip>
              </Pulse>
            </Reveal>
            <Reveal delay={1100} animation={Animation.FadeIn}>
              <Pulse delay={1150}>
                <AllowanceTip>薬剤師会費負担</AllowanceTip>
              </Pulse>
            </Reveal>
            <Reveal delay={1400} animation={Animation.FadeIn}>
              <Pulse delay={1450}>
                <AllowanceTip>交通費支給</AllowanceTip>
              </Pulse>
            </Reveal>
            <Reveal delay={1700} animation={Animation.FadeIn}>
              <Pulse delay={1750}>
                <AllowanceTip>
                  登録販売者資格取得支援
                  <PcBr />
                  ＊薬剤師以外を対象
                </AllowanceTip>
              </Pulse>
            </Reveal>
            <Reveal delay={2000} animation={Animation.FadeIn}>
              <Pulse delay={2050}>
                <AllowanceTip>薬剤師賠償責任保険加入</AllowanceTip>
              </Pulse>
            </Reveal>
            <Reveal delay={2300} animation={Animation.FadeIn}>
              <Pulse delay={2350}>
                <AllowanceTip>週休2日</AllowanceTip>
              </Pulse>
            </Reveal>
          </AllowanceTipContainer>
        </Allowance1Container>

        <Allowance2Container>
          <Reveal delay={2000} animation={Animation.FadeIn}>
            <Allowance2Title>
              県外からの方には
              <SpBr />
              ウェルカム手当を支給
            </Allowance2Title>
          </Reveal>
          <Allowance2ItemContainer>
            <Allowance2ItemWrapper>
              <Reveal delay={2500} animation={Animation.FadeInRight}>
                <Pulse delay={2600}>
                  <TruckImageWrapper>
                    <Image path="recruit_truck@2x.png" />
                  </TruckImageWrapper>
                </Pulse>
                <Allowance2ItemText>引っ越し代支給</Allowance2ItemText>
              </Reveal>
            </Allowance2ItemWrapper>

            <Allowance2ItemWrapper>
              <Reveal delay={2800} animation={Animation.FadeInRight}>
                <Pulse delay={2900}>
                  <RealestateImageWrapper>
                    <Image path="recruit_realestate@2x.png" />
                  </RealestateImageWrapper>
                </Pulse>
                <Allowance2ItemText>不動産屋紹介</Allowance2ItemText>
              </Reveal>
            </Allowance2ItemWrapper>

            <Allowance2ItemWrapper>
              <Reveal delay={3100} animation={Animation.FadeInRight}>
                <Pulse delay={3200}>
                  <HousingImageWrapper>
                    <Image path="recruit_housing@2x.png" />
                  </HousingImageWrapper>
                </Pulse>
                <Allowance2ItemText>住宅手当支給</Allowance2ItemText>
              </Reveal>
            </Allowance2ItemWrapper>
          </Allowance2ItemContainer>
          <Reveal delay={1500} animation={Animation.FadeIn}>
            <Allowance2Description>
              私たちシンビ
              堂は軽井沢エリアを軸に、5店舗を展開している調剤薬局です。
              <PcBr />
              避暑地としても有名な地域で夏は涼しくエアコン要らずで快適です。
              <PcBr />
              冬は寒いですが都会にはない綺麗な景色と空気に囲まれ日本にいることを忘れます。
              <PcBr />
              アウトレットも近く軽井沢の素敵なライフスタイルを提供するウェルカム手当を支給しています。
            </Allowance2Description>
          </Reveal>
        </Allowance2Container>

        <AboutContainer>
          <Reveal delay={1000} animation={Animation.FadeIn}>
            <AboutTitle>ABOUT US</AboutTitle>
          </Reveal>
          <AboutImageWrapper>
            <Image path="recruit_image2.jpg" />
          </AboutImageWrapper>
          <Reveal delay={500} animation={Animation.FadeInUp}>
            <AboutMessage>「暮らしの信頼を提供する薬局」</AboutMessage>
          </Reveal>
          <Reveal delay={800} animation={Animation.FadeInUp}>
            <AboutSubMessage>
              新旧住民の方々のためになるお薬のご相談や地域情報や道案内など、
              <PcBr />
              暮らしの安心を提供できるコミュニケーションを一番大切にしております。
              <PcBr />
              シンビ堂は、マニュアルではないお客様とのコミュニケーションを大切にする薬局です。
            </AboutSubMessage>
          </Reveal>
          <AboutBorder />
          <MessageComponent showSubmessage={false} />
        </AboutContainer>

        <FlowContainer>
          <Reveal delay={1500} animation={Animation.FadeIn}>
            <FlowTitle>採用までの流れ</FlowTitle>
          </Reveal>
          <FlowItemContainer>
            <FlowItemWrapper width={width}>
              <Reveal delay={2000} animation={Animation.FadeInUp}>
                <Pulse delay={2050}>
                  <FlowItemImageTextWrapper>
                    <FlowImage>
                      <EntryImageWrapper>
                        <Image path="recruit_entry@2x.png" />
                      </EntryImageWrapper>
                    </FlowImage>
                    <FlowItemText>ご応募</FlowItemText>
                  </FlowItemImageTextWrapper>
                </Pulse>
                <FlowItemDescription>
                  まずは気軽に電話か応募フォームにてお問い合わせください。
                </FlowItemDescription>
              </Reveal>
            </FlowItemWrapper>

            <FlowItemWrapper width={width}>
              <Reveal delay={2500} animation={Animation.FadeInUp}>
                <Pulse delay={2550}>
                  <FlowItemImageTextWrapper>
                    <FlowImage>
                      <InterviewImageWrapper>
                        <Image path="recruit_interview@2x.png" />
                      </InterviewImageWrapper>
                    </FlowImage>
                    <FlowItemText>見学・面接</FlowItemText>
                  </FlowItemImageTextWrapper>
                </Pulse>
                <FlowItemDescription>
                  連絡を取り合い、見学・面接の日程を決めます。
                  <br />
                  ※交通費は支給致します。
                </FlowItemDescription>
              </Reveal>
            </FlowItemWrapper>

            <FlowItemWrapper width={width}>
              <Reveal delay={3000} animation={Animation.FadeInUp}>
                <Pulse delay={3050}>
                  <FlowItemImageTextWrapper>
                    <FlowImage>
                      <HireImageWrapper>
                        <Image path="recruit_hire@2x.png" />
                      </HireImageWrapper>
                    </FlowImage>
                    <FlowItemText>採用</FlowItemText>
                  </FlowItemImageTextWrapper>
                </Pulse>
                <FlowItemDescription>
                  見学・面談を終えた後に弊社から採用の可否をお電話・メールにご連絡を致します。
                </FlowItemDescription>
              </Reveal>
            </FlowItemWrapper>
          </FlowItemContainer>
        </FlowContainer>
        <ContactUs />
      </Container>
      <Link to="/recruit/entry">
        <LinkWrapper onMouseEnter={handleHover} onMouseLeave={handleHover}>
          {hover ? (
            <LinkIconWrapper>
              <Image path="zo_sm_w@2x.png" />
            </LinkIconWrapper>
          ) : (
            <LinkIconWrapper>
              <Image path="zo_sm@2x.png" />
            </LinkIconWrapper>
          )}
          <div>募集要項・ご応募はこちら</div>
        </LinkWrapper>
      </Link>
    </Layout>
  )
}

const LinkWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    bottom: 0;
    -webkit-justify-content: center;
    justify-content: center;
    height: 40px;
    left: 0;
    right: 0;
    z-index: 999;
  `}
  ${customMedia.greaterThan('medium')`
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    bottom: 12px;
    line-height: 45px;
    height: 45px;
    right: 0;
    width: 280px;

    :hover {
      background-color: #C62F68;
    }
  `}

  background-color: #0055A1;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  font-size: 1.6rem;
  -webkit-align-items: center;
  align-items: center;
  font-weight: bold;
  position: fixed;

  a {
    color: #fff;

    :visited {
      color: #fff;
    }
  }
`

const LinkIconWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin-right: 8px;
  `}
  ${customMedia.greaterThan('medium')`  
    margin: 0 10px 0 20px;
  `}

  width: 24px;
`

const Container = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
  `}
`

const BreadcrumbWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 15px 0 0 20px;
  `}
  ${customMedia.greaterThan('medium')`
    top: 590px;
    left: 100px;
    position: absolute;
  `}
`

const Message = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.8rem;
    letter-spacing: 0.12em;
    margin-top: 20px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 2.8rem;
    letter-spacing: 0.2em;
    margin-top: 50px;
  `}

  color: #0055A4;
  font-weight: bold;
  text-align: center;
`

const SubMessage = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.4rem;
    margin-top: 23px;
    padding: 0 18px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 1.6rem;
    letter-spacing: 0.14em;
    line-height: 30px;
    margin-top: 27px;
    text-align: center;
  `}

  color: #0055A4;
`

const MissionWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin-top: 30px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-top: 68px;
  `}
`

const Communication = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
    height: 480px;
  `}
`

const TabletImageWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 20px auto 0;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 42px auto 0;
  `}
  width: 62px;
`

const CommunicationTitle = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.8rem;
    margin-top: 15px;
    letter-spacing: 0.12em;
    text-align: center;
  `}
  ${customMedia.greaterThan('medium')`
    margin-top: 20px;
    font-size: 2.8rem;
    letter-spacing: 0.2em;
    text-align: center;
  `}

  color: #0055A4;
  font-weight: bold;
`

const CommunicationSubTitle = styled.div`
  ${customMedia.lessThan('medium')`
    display: none;
  `}

  text-align: center;

  div {
    background-color: #0055a4;
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: 0.22em;
    margin-top: 15px;
    padding: 5px 13px;
  }
`

const CommunicationContainer = styled.div`
  ${customMedia.lessThan('medium')`
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 20px;
  `}
  ${customMedia.greaterThan('medium')`
    -webkit-justify-content: center;
    justify-content: center;
    margin-top: 48px;
  `}

  display: -webkit-flex;
  display: flex;
`

const Home = styled.div`
  ${customMedia.lessThan('medium')`
    height: 140px;
    margin-bottom: 33px;
    width: 50%;
  `}
  ${customMedia.greaterThan('medium')`
    width: 200px;
  `}

  position: relative;
`

const Virus = styled.div`
  ${customMedia.lessThan('medium')`
    height: 140px;
    margin-bottom: 33px;
    width: 50%;
  `}
  ${customMedia.greaterThan('medium')`
    width: 200px;
  `}

  position: relative;
`

const Vaccine = styled.div`
  ${customMedia.lessThan('medium')`
    height: 140px;
    margin-bottom: 33px;
    width: 50%;
  `}
  ${customMedia.greaterThan('medium')`
    width: 200px;
  `}
  position: relative;
`

const Hospital = styled.div`
  ${customMedia.lessThan('medium')`
    height: 140px;
    margin-bottom: 33px;
    width: 50%;
  `}
  ${customMedia.greaterThan('medium')`
    width: 200px;
  `}

  position: relative;
`

const HomeIconWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 0 auto;
    width: 100px;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 0 auto;
    width: 152px;
  `}
`

const VirusIconWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 0 auto;
    width: 98px;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 0 auto;
    width: 147px;
  `}
`

const VaccineIconWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 0 auto;
    width: 85px;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 0 auto;
    width: 128px
  `}
`

const HospitalIconWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 0 auto;
    width: 76px;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 0 auto;
    width: 114px;
  `}
`

const CommunicationText = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.4rem;
    position: absolute;
    letter-spacing: 0.04em;
    text-align: center;
    top: 95px;
    width: 100%;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 1.6rem;
    text-align: center;
    letter-spacing: 0.02em;
    position: absolute;
    top: 145px;
    width: 100%;
  `}

  color: #0055A4;
  font-weight: bold;
`

const PositionContainer = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
  `}

  background-color: #0055A4;
  overflow: hidden;
`

const EnvironmentMessage = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.4rem;
    height: 27px;
    margin-top: 15px;
    line-height: 27px;
    letter-spacing: 0.1em;
  `}
  ${customMedia.greaterThan('medium')`
    border-radius: 5px;
    font-size: 1.8rem;
    line-height: 31.8px;
    height: 31.8px;
    margin: 56px auto 0;
    padding-left: 15px;
    line-height: 31px;
    letter-spacing: 0.22em;
    width: 390px;
  `}

  color: #0055A4;
  background-color: #fff;
  font-weight: bold;
  text-align: center;
`

const RecruitMessage = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.8rem;
    letter-spacing: 0.12em;
    margin-top: 20px;
    text-align: center;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 2.8rem;
    letter-spacing: 0.2em;
    margin-top: 18px;
    text-align: center;
  `}

  color: #fff;
  font-weight: bold;
`

const PositionContent = styled.div`
  ${customMedia.lessThan('medium')`
    margin-top: 30px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-top: 40px;
  `}
`

const PositionCardWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin-bottom: 30px;
    padding: 0 20px;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 0 auto 40px;
    width: 906px;
  `}
`

const RobotContainer = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
    margin: 0 auto;
    position: relative;
    width: 800px;
  `}
`

const RobotTitle = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.8rem;
    letter-spacing: 0.12em;
    margin-top: 30px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 2.8rem;
    letter-spacing: 0.2em;
    margin-top: 50px;
  `}

  color #00559F;
  font-weight: bold;
  text-align: center;
`

const SpRobotImageWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 0 auto;
    width: 260px;
  `}
  ${customMedia.greaterThan('medium')`
    display: none;
  `}
`

const PcRobotImageWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    display: none;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 40px 0;
    width: 640px;
  `}
`

const RobotDescription = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.4rem;
    letter-spacing: 0.02em;
    margin-bottom: 25px;
    padding: 0 20px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 1.6rem;
    position: absolute;
    left: 300px;
    letter-spacing: 0.14em;
    line-height: 30px;
    top: 350px;
  `}

  color: #00559F;
`

const RecruitImage1Wrapper = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
    img {
      height: 400px;
      object-fit: cover;
      width: 100%;
    }
  `}
`

const Allowance1Container = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
  `}

  background-color: #0055A4;
  overflow: hidden;
`

const Allowance1Title = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.8rem;
    letter-spacing: 0.2em;
    margin-top: 20px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 2.8rem;
    letter-spacing: 0.12em;
    margin-top: 35px;
  `}

  color: #fff;
  font-weight: bold;
  text-align: center;
`

const Allowance1Description = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.4rem;
    letter-spacing: 0.02em;
    margin-top: 20px;
    padding: 0 20px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 1.6rem;
    line-height: 35px;
    letter-spacing: 0.14em;
    margin-top: 30px;
    text-align: center;
  `}

  color: #fff;
`

const AllowanceTipContainer = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 20px 0 25px;
    padding: 0 15px;
  `}
  ${customMedia.greaterThan('medium')`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 30px auto 40px;
    width: 800px;
  `}
`

const AllowanceTip = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.2rem;
    height: 26px;
    margin-bottom: 5px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 10px;
    height: 60px;
    width: 260px;
  `}

  background-color: #fff;
  border-radius: 5px;
  color: #0055a4;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  font-size: 1.4rem;
  letter-spacing: 0.02em;
  text-align: center;
`

const Allowance2Container = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
  `}
`

const Allowance2Title = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.8rem;
    letter-spacing: 0.12em;
    margin-top: 25px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 2.8rem;
    letter-spacing: 0.2em;
    margin-top: 55px;
  `}

  color: #00559F;
  font-weight: bold;
  text-align: center;
`

const Allowance2ItemContainer = styled.div`
  ${customMedia.lessThan('medium')`
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 40px auto 0;
    width: 655px;
  `}

  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
`

const Allowance2ItemWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin-top: 25px;
    width: 50%;
  `}
  ${customMedia.greaterThan('medium')`
    width: calc(100% / 3);
  `}

  position: relative;
`

const TruckImageWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 0 auto;
    width: 80px;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 0 auto;
    width: 145px;
  `}
`

const RealestateImageWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 0 auto;
    width: 85px;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 0 auto;
    width: 154px;
  `}
`

const HousingImageWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 0 auto;
    width: 93px;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 0 auto;
    width: 168px;
  `}
`

const Allowance2ItemText = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    top: 80px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 1.6rem;
    letter-spacing: 0.02em;
    top: 150px;
  `}
  
  color: #00559F;
  font-weight: bold;
  position: absolute;
  text-align: center;
  width: 100%;
`

const Allowance2Description = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.4rem;
    margin: 50px 0 25px;
    letter-spacing: 0.04em;
    padding: 0 20px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 1.6rem;
    line-height: 35px;
    letter-spacing: 0.12em;
    margin: 60px 0 45px;
    text-align: center;
  `}

  color: #00559F;
`

const AboutContainer = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
  `}

  background-color: #00559F;
  overflow: hidden;
`

const AboutTitle = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.6rem;
    letter-spacing: 0.01em;
    margin-top: 20px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 3.6rem;
    letter-spacing: 0.1em;
    margin-top: 40px;
  `}

  color: #fff;
  font-family: 'Quicksand', sans-serif;
  text-align: center;
`

const AboutImageWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin-top: 20px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-top: 40px;
  `}
`

const AboutMessage = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.8rem;
    letter-spacing: 0.12em;
    margin-top: 15px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 2.8rem;
    letter-spacing: 0.06em;
    margin-top: 35px;
  `}

  color: #fff;
  font-weight: bold;
  text-align: center;
`

const AboutSubMessage = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.4rem;
    letter-spacing: 0.2em;
    margin-top: 15px;
    padding: 0 20px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 1.6rem;
    line-height: 30px;
    letter-spacing: 0.14em;
    margin-top: 30px;
    text-align: center;
  `}

  color: #fff;
`

const AboutBorder = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 15px 20px 0;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 60px 100px 0;
  `}

  background-color: #fff;
  height: 2px;
`

const FlowContainer = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
  `}
`

const FlowTitle = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.8rem;
    letter-spacing: 0.12em;
    margin-top: 25px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 2.8rem;
    letter-spacing: 0.2em;
    margin-top: 55px;
  `}

  color: #00559F;
  font-weight: bold;
  text-align: center;
`

const FlowItemContainer = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin: 35px auto 0;
    width: 800px;
  `}
`

const FlowItemWrapper = styled.div<{ width: number }>`
  ${props => customMedia.lessThan('medium')`
    padding: 25px 20px 0;

    :first-child {
      :after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: -40px;
        left: 0;
        border-style: solid;
        border-color: #fff transparent transparent transparent;
        border-width: 40px ${props.width / 2}px 0 ${props.width / 2}px;
        z-index: 1;
      }
    }

    :nth-child(2) {
      background-color: #F1F5F9;
      position: relative;

      :after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: -40px;
        left: 0;
        border-style: solid;
        border-color: #F1F5F9 transparent transparent transparent;
        border-width: 40px ${props.width / 2}px 0 ${props.width / 2}px;
        z-index: 1;
      }
    }

    :nth-child(3) {
      background-color: #CBDCEC;

      :after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: -40px;
        left: 0;
        border-style: solid;
        border-color: #CBDCEC transparent transparent transparent;
        border-width: 40px ${props.width / 2}px 0 ${props.width / 2}px;
        z-index: 1;
      }
    }
  `}
  ${customMedia.greaterThan('medium')`
    width: calc(100% / 3);
  `}

  position: relative;
`

const EntryImageWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    width: 113px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-left: 30px;
    width: 170px;
  `}
`

const InterviewImageWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    width: 115px;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 0 auto;
    width: 174px;
  `}
`

const HireImageWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    width: 100px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-left: 30px;
    width: 154px;
  `}
`

const FlowItemImageTextWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    align-items: center;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 1.6rem;
    top: 150px;
  `}
  
  color: #00559F;
  font-weight: bold;
  text-align: center;
`

const FlowImage = styled.div`
  ${customMedia.lessThan('medium')`
    width: 140px;
  `}
`

const FlowItemText = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.6rem;
    top: 80px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 1.8rem;
    margin-top: 15px;
    top: 150px;
  `}
  
  color: #00559F;
  font-weight: bold;
  letter-spacing: 0.12em;
  text-align: center;
`

const FlowItemDescription = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.4rem;
    margin-top: 17px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 1.6rem;
    line-height: 25px;
    letter-spacing: 0.06em;
    margin-top: 15px;
    padding: 0 20px;
  `}
  
  color: #00559F;
`

const SomeComponent = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
  `}
`

export default IndexPage
