import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { customMedia } from '../../common/customMedia'
import { SpBr } from '../../common/SpBr'
import RubberBand from 'react-reveal/RubberBand'

import { Image } from '../../common/Image'

export interface Props {
  imagePath: string
  type: string
  color: string
  title: string
  salaryType: string
  salaryMin: string
  salaryMax?: string
  requirement: string
  description: string
  showBalloon?: boolean
}

export const PositionCard = (props: Props) => {
  const { imagePath, type, color, title, salaryType, salaryMin, salaryMax, requirement, description, showBalloon } = props

  return (
    <Container>
      {showBalloon && <Balloon><RubberBand delay={1000}><div>新卒者<br />大歓迎</div></RubberBand></Balloon>}
      <ImageWrapper>
        <Image path={imagePath} />
      </ImageWrapper>
      <DescriptionWrapper>
        <Type color={color}><div>{type}</div></Type>
        <Title>{title.split('\n').map((m, i) => <span key={i}>{m}<SpBr /></span>)}</Title>
        <SalaryHeader>{salaryType}</SalaryHeader>
        <Salary>
          {salaryMin}〜{salaryMax}
        </Salary>
        <RequirementHeader>応募条件</RequirementHeader>
        <Requirement>{requirement.split('\n').map((m, i) => <span key={i}>{m}<br /></span>)}</Requirement>
        <Border />
        <Description>{description}</Description>
      </DescriptionWrapper>
    </Container>
  )
}

const Balloon = styled.div`
  ${customMedia.lessThan("medium")`
    top: 117px;
    right: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    top: -12px;
    right: 70px;
  `}
  
  background-color: #C42F68;
  border-radius: 50%;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  position: absolute;
  height: 60px;
  width: 60px;
  z-index: 99;

  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;

  :after{
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    pointer-events: none;
    position: absolute;
    border-color: rgba(196, 47, 104, 0);
    border-top-width: 15px;
    border-bottom-width: 15px;
    border-left-width: 4px;
    border-right-width: 4px;
    margin-left: -4px;
    border-top-color: #C42F68;
    top: 70%;
    left: 5%;
    transform: rotate(45deg);
  }
`

const Container = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
  `}

  position: relative;
`

const ImageWrapper = styled.div`
    height: 130px;
    width: 100%;

  ${customMedia.lessThan("medium")`
    img {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      height: 130px !important;
      object-fit: cover;
      overflow: hidden;
    }
  `}
  ${customMedia.greaterThan("medium")`
    height: 330px;
    width: 50%;

    div {
      overflow: visible !important;
    }

    img {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      object-fit: cover;
      height: 330px !important;
    }
  `}
`

const DescriptionWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 330px;
    padding: 17px 30px;
    width: 50%;
  `}

  background-color: #fff;
  color: #0055A4;
  font-size: 1.4rem;
`

const Type = styled.div<{color: string}>`
  text-align: center;

  div {
    border: ${props => props.color === 'blue' ? '2px #0055A4 solid' : '2px #CB2F68 solid'};
    color: ${props => props.color === 'pink' && '#CB2F68'};
    border-radius: 18px;
    display: inline-block;
    font-weight: bold;
    letter-spacing: 0.1em;
    line-height: 26px;
    height: 28px;
    width: 120px;
  }
`

const Title = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.12em;
  margin-top: 10px;
`

const SalaryHeader = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  background-color: #0055A4;
  border-radius: 18px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 0.24em;
  margin-top: 15px;
  padding: 2px 0;
  text-align: center;
`

const Salary = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  letter-spacing: 0.04em;
  margin-top: 10px;
`

const RequirementHeader = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  background-color: #0055A4;
  border-radius: 18px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 0.24em;
  margin-top: 14px;
  padding: 2px 0;
  text-align: center;
`

const Requirement = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  letter-spacing: 0.04em;
  margin-top: 10px;
`

const Border = styled.div`
  background-color: #0055A4;
  height: 2px;
  margin-top: 14px;
`

const Description = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  letter-spacing: 0.02em;
  margin-top: 14px;
`
